import React from 'react';
import { removeItem } from '../../store/carSlice';
import { useDispatch } from 'react-redux';

const ButtonTrash = ({ id }) => {
    const dispatch = useDispatch();
    const onclick = () => {
        dispatch(removeItem(id))
    };
    return (
        <i className="icon-trash" onClick={onclick} onKeyDown={() => null} />
    );
};

export default ButtonTrash;

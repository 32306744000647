import React from 'react';
import { useLocation } from 'react-router-dom';
import CarTop from '../car/carTop';
// import WidgetLogin from '../login/widgetTop';

const Header = (props) => {
    const location = useLocation();
    return (
        <header id="headerPpal" className="justify-content-center">
            <section className="container d-flex justify-content-between align-items-center">
                <a href={props.urlBack} className="logo-header">
                    <img src={props.urlLogo} alt="Logo" />
                </a>
                {!(
                    location.pathname.includes('realizarPago') ||
                    location.pathname.includes('confirmacionCompra')
                ) && (
                    <div className="d-flex align-items-center">
                        <div className="carTopWrap">
                            <CarTop />
                        </div>
                    </div>
                )}
            </section>
        </header>
    );
};

export default Header;

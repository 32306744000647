import axios from 'axios';
import http from './http';

export const loginWithCode = async (email, eventid) => {
    let resp = await axios.post(
        `${process.env.REACT_APP_URL_TM}cliente/get-tickets`,
        {
            email,
            event_id: parseInt(eventid),
            generate_otp: false
        }
    );
    return resp;
};

export const generateCode = async (email, functionId, resend = false) => {
    let resp = await axios.post(
        `${process.env.REACT_APP_URL_TM}cliente/generate-otp-code`,
        {
            email,
            type: 'default',
            function_id: functionId,
            resend_if_exists: resend
        }
    );
    return resp;
};

export const verifyCode = async (email, code, functionId, eventId) => {
    let resp = await http.post(
        `${process.env.REACT_APP_URL_TM}cliente/validate-otp-code`,
        {
            email,
            otp_code: code,
            function_id: functionId,
            event_id: eventId
        }
    );
    return resp;
};

export const registerClient = async (data) => {
    let resp = await http.post(`/clients`, data);
    return resp;
};

export const changePerfil = async (clientId, data, token) => {
    let resp = await http.patch(`/clients/${clientId}/`, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return resp;
};

export const addDirecction = async (clientId, data, token) => {
    let resp = await http.post(`/clients/${clientId}/client-addresses`, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return resp;
};
export const getDirecciones = async (clientId, token) => {
    let resp = await http.get(`/clients/${clientId}/client-addresses`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return resp;
};

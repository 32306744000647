import http, { getAll } from './http';

export const getComercios = async (currency = 'USD', companyId) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(`/${companyId}/branches/`);
    return resp;
};
export const getDetalleComercio = async (
    currency = 'USD',
    companyId,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await getAll([
        http.get(`/${companyId}/branches/${branchId}`),
        http.get(`/${companyId}/branches/${branchId}/work-schedules`)
    ]);
    let data = { ...resp[0].data, horarios: resp[1].data.data };
    return data;
};

export const getCategoriasPorComercio = async (
    currency,
    companyId,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(
        `/${companyId}/branches/${branchId}/categories/all`
    );
    return resp;
};
export const getCategoriasPorComercioConProductos = async (
    currency,
    companyId,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(
        `/${companyId}/branches/${branchId}/categories/all?with=goods`
    );
    return resp;
};

export const getCategoriaPorId = async (currency = 'USD', companyId, idCat) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(`/${companyId}/categories/${idCat}`);
    return resp;
};

export const getProductosPorCatLimit = async (
    currency,
    companyId,
    idCat,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(
        `/${companyId}/branches/${branchId}/branch-goods?category_id=${idCat}&with=branch_good_properties&limit=100&sort_by=list_order`
    );
    return resp;
};
export const getProductosPorCat = async (
    currency,
    companyId,
    idCat,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await getAll([
        await http.get(`/${companyId}/categories/${idCat}`),
        await http.get(
            `/${companyId}  /branches/${branchId}/branch-goods?category_id=${idCat}&with=branch_good_properties&limit=100&sort_by=list_order`
        )
    ]);
    return resp;
};
export const getProductosPorQuery = async (
    currency,
    companyId,
    q,
    branchId
) => {
    http.defaults.headers.get['X-Currency'] = currency;
    let resp = await http.get(
        `/${companyId}/branches/${branchId}/branch-goods/search?q=${q}`
    );
    return resp;
};

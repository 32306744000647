import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import CodeComponent from './codeComponent';
import { generateCode, loginWithCode, verifyCode } from '../../services/auth';
import { changeInput, validarInput } from '../utils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoUser } from '../../store/usuarioSlice';
import { useTranslation } from 'react-i18next';

const LoginComponent = ({ changeAction, hideTitle, setAuth }) => {
    const { t } = useTranslation();
    const shop = useSelector((store) => store.shop);
    const [, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const [email, setEmail] = useState({
        value: '',
        type: 'email',
        required: true
    });
    const [WaitVerify, setWaitVerify] = useState(false);
    const [codigo, setCodigo] = useState('');
    // const dispatch = useDispatch();
    useEffect(() => {
        setError(false);
    }, [email]);

    const sendCode = async () => {
        setLoading(true);
        let { status } = await verifyCode(
            email.value,
            codigo,
            user[0].funcion.Id,
            shop.config.EventID
        );
        setLoading(false);
        // console.log(status, data);
        if (status === 200) {
            setWaitVerify(true);
            dispatch(setInfoUser(user));
            document.getElementById('email').value = user[0].cliente.Email;
        } else {
            toast.error('El código es incorrecto', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    const ingresar = async () => {
        let _v = validarInput(email);
        // console.log(_v);
        setEmail(_v);
        if (_v.error) {
            return 0;
        }
        setLoading(true);
        let { status, data } = await loginWithCode(
            email.value,
            shop.config.EventID
        );
        // console.log(status, data);
        if (status === 200 && data.tickets.length > 0) {
            try {
                setUser(data.tickets);
                // console.log(process.env.REACT_APP_SHOP_FUNCTION_ID);
                const resp = await generateCode(
                    email.value,
                    data.tickets[0].funcion.Id
                );
                setLoading(false);
                console.log(resp);
                setWaitVerify(true);
            } catch (error) {
                setLoading(false);
                if (
                    error.response &&
                    error.response.data.e === 'OTP already exist and is valid'
                ) {
                    setWaitVerify(true);
                }
                // console.log(error);
            }
        } else if (status === 200 && data.tickets.length === 0) {
            toast('No posee tickets asociados a ese correo', {
                type: 'error'
            });
            setLoading(false);
        } else if (
            status === 400 &&
            data.e === 'OTP already exist and is valid'
        ) {
            setWaitVerify(true);
            setLoading(false);
        }
    };
    return !loading ? (
        <>
            {WaitVerify && user ? (
                <CodeComponent
                    changeCodigo={setCodigo}
                    codigo={codigo}
                    length={4}
                    back={() => setWaitVerify(false)}
                    ingresar={sendCode}
                    hideTitle={hideTitle}
                    email={email.value}
                    funcionId={user[0].funcion.Id}
                />
            ) : (
                <div className="loginComponent d-flex w-100 flex-column">
                    {!hideTitle && (
                        <h2 className="textTitle">{t('Login')}</h2>
                    )}
                    <label htmlFor="email" className="textSec">
                        {t('Correo')}
                    </label>
                    <div className="_wrapinput">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            value={email.value}
                            onChange={(e) => setEmail(changeInput(e, email))}
                        />
                        {email.error && (
                            <span className="error">{email.errorMensaje}</span>
                        )}
                    </div>
                    <div className="buttons row d-flex mt-auto">
                        <div className="col-12">
                            <button
                                className={`btn btn-block btnGen`}
                                onClick={ingresar}
                            >
                                <span>{t('Continuar')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    ) : (
        <div className="d-flex align-self-center mx-auto justify-content-center p-5">
            <div
                className="spinner-border text-secondary spinner-custom"
                role="status"
            >
                <span className="sr-only"></span>
            </div>
        </div>
    );
};

export default LoginComponent;

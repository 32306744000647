import React from 'react';
import Skeleton from 'react-loading-skeleton';

const GridProdSkeleton = () => {
    return (
        <div className="generalSkeleton">
            <div className="sk-main">
                <div className="sk-categorias">
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                </div>
                <div className="sk-categorias">
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                    <Skeleton className="sk-cat" />
                </div>
            </div>
        </div>
    );
};

export default GridProdSkeleton;

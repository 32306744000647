import React from 'react';
import Logo from '../../assets/images/logo-footer.svg';
const Footer = (props) => {
    return (
        <footer id="footerPpal" className="d-flex align-items-center">
            <section className="container d-flex w-100 flex-wrap justify-content-between">
                <div className="redes">
                    <a href="#">
                        <i className="icon-facebook"></i>
                    </a>
                    <a href="#">
                        <i className="icon-tweeter"></i>
                    </a>
                    <a href="#">
                        <i className="icon-instagram"></i>
                    </a>
                </div>
                <span>
                    <img src={Logo} alt="logo" />
                </span>
            </section>
        </footer>
    );
};

export default Footer;

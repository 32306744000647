import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import './translate/i18n';
import Tienda from './pages/home';
import ConfirmarCompra from './pages/confirmacionCompra';
import { useConfig } from './services/useShop';
import { setShopConfig } from './store/shopSlice';
import { useDispatch, useSelector } from 'react-redux';
import GeneralSkeleton from './components/skeletons/general';
import { install } from 'ga-gtag';
// import ReactPixel from 'react-facebook-pixel';
// const options = {
//     autoConfig: true,
//     debug: false,
// };
// ReactPixel.init(process.env.REACT_APP_PIXEL, options);
// ReactPixel.pageView();
// ReactPixel.track(event, data);

const App = () => {
    const { data, error, isLoading } = useConfig(window.location.hostname);
    const Idioma = useSelector((store) => store.shop.config?.Idioma);
    const dispatch = useDispatch();
    useEffect(() => {
        if (data) {
            dispatch(setShopConfig(data.InfoShop));
            if (data.InfoShop.gtag) {
                install(data.InfoShop.gtag);
            }
        }
    }, [data, error, isLoading]);
    useEffect(() => {
        i18n.changeLanguage(Idioma ? Idioma.toLowerCase() : 'en');
    }, [Idioma]);

    if (error) {
        return <>error</>;
    }

    return isLoading || !data.InfoShop ? (
        <GeneralSkeleton />
    ) : (
        <main className={`App theme ${data.InfoShop.ShopTheme}`}>
            <Helmet>
                <link
                    rel="icon"
                    type="image/png"
                    href={data.InfoShop.ShopUrlLogo}
                />
                <title>{data.InfoShop.ShopName}</title>
                <meta name="description" content={data.InfoShop.ShopDesc} />
                <style>
                    {`:root{
                        --colorPpal: ${data.InfoShop.ColorPpal};
                    }`}
                </style>
                {/* <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
                ></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', ${process.env.REACT_APP_GTAG});
                    `}
                </script> */}
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/*" element={<Tienda />} />
                    <Route
                        path="/compraFinalizada"
                        element={<ConfirmarCompra />}
                    />
                </Routes>
            </Router>
        </main>
    );
};

export default App;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GeneralSkeleton = () => {
    return (
        <div className="generalSkeleton">
            <div className="sk-main">
                <div className="sk-header">
                    <div className="container  d-flex justify-content-between align-items-center">
                        <Skeleton
                            className="sk-logo"
                            style={{ display: 'block', width: '200px' }}
                        />
                        <Skeleton
                            className="sk-car"
                            style={{ display: 'block', width: '100px' }}
                        />
                    </div>
                </div>
                <Skeleton className="sk-banner" />
                <div className="container">
                    <div className="sk-categorias">
                        <Skeleton className="sk-cat" />
                        <Skeleton className="sk-cat" />
                        <Skeleton className="sk-cat" />
                        <Skeleton className="sk-cat" />
                    </div>
                    <Skeleton className="sk-banner" />
                </div>
            </div>
            <div className="">
                <Skeleton className="sk-footer" />
            </div>
        </div>
    );
};

export default GeneralSkeleton;

import React, { useState, useEffect, useRef } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { useLocation } from "@reach/router";
import { getTokenLuka, getTokenLukaProd } from '../../services/car';

const Lukapay = ({
    monto,
    procesarPago,
    validarDatos,
    update,
    moneda,
    setShowSkeleton,
    showSkeleton,
    noEmail,
    nombre
}) => {
    // const location = useLocation();
    const [Ready, setReady] = useState(false);
    const shop = useSelector((store) => store.shop);
    let init = useRef(null);
    // const [token, setToken] = useState(null);

    useEffect(() => {
        (async () => {
            if (process.env.REACT_APP_ENV === 'production') {
                let { status, data } = await getTokenLukaProd(
                    shop.config.ComercioID,
                    shop.config.PaisID
                );
                // console.log(headers);
                if (status === 200) {
                    // setToken(data.Token);
                    if (!init.current) {
                        initLuka(data.Token, data.Id, monto, false);
                    }
                }
            } else {
                let { status, headers } = await getTokenLuka({
                    Username: process.env.REACT_APP_LUKA_USER,
                    Password: process.env.REACT_APP_LUKA_PASS
                });
                // console.log(headers);
                if (status === 200) {
                    // setToken(headers.token);
                    // setIdTraza(headers.id);
                    if (!init.current) {
                        initLuka(headers.token, headers.id, monto, false);
                    }
                }
            }
        })();
    }, []);

    // useEffect(() => {
    //     console.log(new Date());
    //     if (!document.getElementById('luka')) {
    //         (async () => {
    //             token && idTraza && ;
    //         })();
    //     }
    // }, [token, idTraza, update, moneda]);

    const initLuka = (token, idTraza, monto, sk) => {
        init.current = true;
        window.luka.init(
            token,
            'container',
            {
                monto: parseFloat(monto),
                numeroDecimales: 2,
                separadorMiles: ',',
                separadorDecimal: '.'
            },
            null,
            idTraza,
            {
                moneda,
                terminos: {
                    tipo: '1',
                    comportamiento: 'https://ticketmundo.com.ve/terms-use'
                },
                idioma: 'esp',
                color: shop.config.ColorPpal.replace('#', ''),
                // metodos: 'stripe,paypal',
                metodos:
                    shop.config.MetodosDePago ||
                    'tdc_bs,tdc_mc,tdd_mc,pm_bod,paypal',
                paypal: {
                    color: 'gold'
                },
                fuente: 'Open Sans, R-R',
                seleccion: 2,
                showSkeletonLoading: sk,
                fnValidacionEsAsync: true
            },
            function (result, error) {
                if (error && error.Error.length > 0) {
                    console.log('error:', error);
                    toast.error(
                        'Error al procesar transacción, verifique e intente nuevamente',
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        }
                    );
                } else {
                    procesarPago(result);
                }
            },
            async function () {
                return await (async function () {
                    let email = document.getElementById('email').value;
                    // console.log(email, email.trim() !== '');
                    if (email.trim() !== '') {
                        window.luka.updateEmail(email, token);
                    } else {
                        noEmail();
                        return false;
                    }

                    return await validarDatos();
                })();
            },
            function () {
                console.log('componente cargado');
                setReady(true);
                setShowSkeleton(false);
            },
            {
                nombrePagador: nombre
            }
        );
    };

    useEffect(() => {
        if (Ready) {
            // window.luka.updateMonto(monto, token);
        }
    }, [monto]);

    return (
        <>
            <div
                id="container"
                // style={{ display: `${showSkeleton ? 'none' : 'block'}` }}
            ></div>
        </>
    );
};

export default Lukapay;

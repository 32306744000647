import React from 'react';
import 'react-whatsapp-widget/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
// import { WhatsAppWidget } from 'react-whatsapp-widget';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from './header';
import HeaderConfirm from './header/headerConfirm';
import Footer from './footer';
// import ModalAuth from './login/ModalAuth';
import '../assets/styles/global.scss';
import GeneralSkeleton from './skeletons/general';

const Layout = ({ children, header = 'basic', orderId = '' }) => {
    // const showModalAuth = useSelector((state) => state.usurio.showModalAuth);
    const shop = useSelector((state) => state.shop);

    return !shop.config ? (
        <GeneralSkeleton />
    ) : (
        <main
            id="aplicationShop"
            style={{ paddingTop: header === 'confirm' ? 0 : '' }}
        >
            {header === 'confirm' ? (
                <HeaderConfirm orderId={orderId} urlLogo={shop.config.ShopUrlLogo} urlBack={shop.config.UrlBack}/>
            ) : (
                <Header urlLogo={shop.config.ShopUrlLogo} urlBack={shop.config.UrlBack}/>
            )}
            <section id="middleLayout">{children}</section>
            <Footer />
            <ToastContainer />
        </main>
    );
};

export default Layout;

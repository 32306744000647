import React, { useState } from 'react';
import Countdown from 'react-countdown';
// import { useDispatch, useSelector } from 'react-redux';
// import InputCode from './InputCode';

import useDigitInput from 'react-digit-input';
import { generateCode } from '../../services/auth';
import { useTranslation } from 'react-i18next';

const CodeComponent = ({
    changeCodigo,
    codigo,
    email,
    ingresar,
    back,
    length,
    hideTitle,
    funcionId
}) => {
    const [resending, setResending] = useState(null);
    const { t } = useTranslation();
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 4,
        value: codigo,
        onChange: changeCodigo
    });

    const ResendCode = async () => {
        setResending(new Date().getTime() + 30000);
        const resp = await generateCode(email, funcionId, true);
        console.log(resp)
    };

    return (
        <div className="codeComponent d-flex w-100 flex-column">
            {!hideTitle && <h2 className="textTitle">{t('IngresaCodigo')}</h2>}

            <p>{t('IngresaCodigo2')}</p>
            <p>
                <b>{email}</b>
            </p>

            <div className="inputCode">
                {digits.map((digit, index) => (
                    <input
                        key={index}
                        inputMode="decimal"
                        className="singleCode"
                        autoFocus={index === 0}
                        {...digit}
                    />
                ))}
            </div>

            <div className="buttons row d-flex mt-auto">
                <div className="col-6">
                    <button
                        className="btn btn-block btnOutline"
                        onClick={() => ResendCode()}
                        disabled={resending !== null}
                    >
                        {resending ? (
                            <Countdown
                                date={resending}
                                renderer={({ seconds, completed }) => {
                                    if (completed) {
                                        setResending(null);
                                        return null;
                                    } else {
                                        return <span>{t('Espere')} {seconds} seg</span>;
                                    }
                                }}
                            />
                        ) : (
                            <span>{t('Reenviar')}</span>
                        )}
                    </button>
                </div>
                <div className="col-6">
                    <button
                        className={`btn btn-block btnGen mx-auto ${
                            codigo.length === length ? '' : 'disabled'
                        }`}
                        onClick={() => ingresar()}
                        disabled={codigo.length !== length}
                    >
                        <span>{t('Continuar')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CodeComponent;

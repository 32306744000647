import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import { useLocation } from 'react-router-dom';
import { getProductosCar } from '../../services/car';
// import { formatMonto } from '../utils';
import DetailCar from './DetailCar';
import { setShowCar } from '../../store/carSlice';
import { useTranslation } from 'react-i18next';

const CarTop = () => {
    // const location = useLocation();
    const { t } = useTranslation();
    const carStore = useSelector((state) => state.car);
    const shop = useSelector((state) => state.shop);
    // const [, setSubTotal] = useState(0);
    const [Loading, setLoading] = useState(false);
    const [CantidadProds, setCantidadProds] = useState(0);
    const [Productos, setProductos] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let cant = 0;
        carStore.productos.map((p) => {
            cant = cant + p.cantidad;
        });
        setCantidadProds(cant);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [carStore.productos]);

    useEffect(() => {
        // dispatch({ type: CAR_ACTIONTYPES.TOGGLE_CAR, payload: false });
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);
    useEffect(() => {
        // dispatch({ type: CAR_ACTIONTYPES.TOGGLE_CAR, payload: false });
        // console.log(carStore.productos)
    }, [carStore.productos]);

    useEffect(() => {
        (async () => {
            if (carStore.showCar && carStore.productos.length > 0) {
                let pArray = [];
                let resp = await getProductosCar(
                    shop.config.ShopCurrency,
                    shop.config.CompanyID,
                    carStore.productos
                );
                resp.map((r) => {
                    if (r.status === 200) {
                        pArray.push(r.data);
                    }
                });
                setProductos(pArray || []);
            }
        })();
        document.body.style.overflowY = carStore.showCar ? 'hidden' : 'auto';
    }, [carStore.showCar]);

    useEffect(() => {
        // if (Productos != null) {
        //     let acum = 0;
        //     let cant = 0;
        //     carStore.productos.map((p) => {
        //         acum =
        //             acum +
        //             formatMonto(
        //                 Productos.find((n) => n.id === p.id).custom_data
        //                     .display_price_e2,
        //                 2,
        //                 ',',
        //                 '.'
        //             ) *
        //                 p.cantidad;
        //         cant = cant + p.cantidad;
        //     });
        //     setSubTotal(acum);
        //     setCantidadProds(cant);
        // }
    }, [Productos, carStore.productos]);
    return (
        <>
            <button
                id="buttonCarTop"
                onClick={() => dispatch(setShowCar(!carStore.showCar))}
                className={carStore.showCar || CantidadProds ? 'active' : ''}
            >
                {Loading ? (
                    <div
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                    >
                        <span className="sr-only"></span>
                    </div>
                ) : (
                    <>
                        <i className="icon-car"></i>
                        <span>{CantidadProds}</span>
                    </>
                )}
            </button>
            {carStore.showCar && (
                <>
                    <div
                        id="overlayTopCar"
                        onClick={() => dispatch(setShowCar(false))}
                    ></div>
                    {carStore.productos.length ? (
                        <DetailCar />
                    ) : (
                        <div id="detailCarTop">
                            <div className="noProducts">
                                <p>{t('CarroVacio')}</p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CarTop;

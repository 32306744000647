import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { formatMonto } from '../components/utils';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { clearCar } from '../store/carSlice';
import { useTranslation } from 'react-i18next';

// markup
const ConfirmarCompra = () => {
    const { t } = useTranslation();
    const tickets = useSelector((store) => store.usuario.datos);
    const car = useSelector((store) => store.car.productos);
    const shop = useSelector((store) => store.shop);
    const location = useLocation();
    const dispatch = useDispatch();
    const ORDER = location.state;
    console.log(ORDER);

    useLayoutEffect(() => {
        if (!location.state || car.length === 0) {
            document.location.href = '/';
        }
    }, []);
    useEffect(() => {
        dispatch(clearCar());
    }, []);
    return (
        <Layout header={'confirm'} orderId={ORDER.orden.orderId}>
            <Seo title={`${shop.config.ShopName} - Confirmación de Compra`} />
            <div id="pageConfirm">
                <section className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 mb-lg-0">
                            <div className="infoProductos">
                                <div className="title">
                                    {t('TuOrden')} #{ORDER.orden.orderId}
                                </div>
                                <div className="productos">
                                    <div className="title-table">
                                        <span></span>
                                        <span>{t('Descripcion')}</span>
                                        <span>{t('Cantidad')}</span>
                                    </div>
                                    {ORDER &&
                                        ORDER.orden.productos.length > 0 &&
                                        ORDER.car.productos.map((p) => {
                                            let prodTemp =
                                                location.state.orden.productos.find(
                                                    (n) => n.id === p.id
                                                );

                                            return (
                                                <div
                                                    className="itemCar d-flex"
                                                    key={p.id}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <figure>
                                                            <img
                                                                src={
                                                                    prodTemp &&
                                                                    prodTemp
                                                                        .picture_urls[0]
                                                                }
                                                                alt="img prod"
                                                            />
                                                        </figure>
                                                        <div>
                                                            <p className="nombre">
                                                                {prodTemp &&
                                                                    prodTemp.name}
                                                            </p>
                                                            <p
                                                                className="desc"
                                                                style={{
                                                                    color: '#BEBEBE',
                                                                    fontSize:
                                                                        '14px'
                                                                }}
                                                            >
                                                                {prodTemp &&
                                                                    prodTemp.details}
                                                            </p>
                                                            <p className="nombre">
                                                                {getProperty(
                                                                    prodTemp,
                                                                    p
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="d-flex align-items-end flex-column">
                                                            <div className="precio">
                                                                {p.cantidad}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-5">
                            <div className="title">{t('MetodoEnvio')}</div>
                            <div className="funcion">
                                <div className="title mb-4">
                                    {t('RetiroEvento')}
                                </div>
                                <figure className="mb-4">
                                    <img
                                        src={shop.config.UrlEventoImg}
                                        alt=""
                                    />
                                </figure>
                                <div className="title">
                                    {tickets[0].funcion.EventoNombre}
                                </div>
                                <div className="fecha">
                                    {moment(tickets[0].funcion.Fecha).format(
                                        'DD-MM-YYYY HH:mm:ss'
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 mb-5">
                            <div className="title">{t('NotaImportante')}</div>
                            <div className="nota">
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('Evento')}:
                                    </b>{' '}
                                    {t('TextoEvento')}
                                </p>
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('Pedido')}
                                    </b>{' '}
                                    {t('TextoPedido')}
                                </p>
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('Retiro')}
                                    </b>{' '}
                                    {t('TextoRetiro')}
                                </p>
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('Devoluciones')}
                                    </b>{' '}
                                    {t('TextoDevoluciones')}
                                </p>
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('Responsabilidad')}
                                    </b>{' '}
                                    {t('TextoResponsabilidad')}
                                </p>
                                <p>
                                    <b style={{ fontWeight: '700' }}>
                                        {t('TextoResponsabilidad2')}
                                    </b>
                                </p>
                            </div>
                        </div> */}

                        {shop.config.MapaIframeUrl && (
                            <div className="col-12 mb-5">
                                <div className="title mb-4">
                                    {t('ComoLlegar')}
                                </div>
                                <div className="address">
                                    <p>{shop.config.Recinto}</p>
                                    <iframe
                                        src={shop.config.MapaIframeUrl}
                                        width="100%"
                                        height="380px"
                                        style={{ borderRadius: '10px' }}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        )}

                        <div className="col-12 mb-5">
                            <div className="title mb-4 text-center">
                                {t('Ayuda')}
                            </div>
                            <p className="text-center mb-4">
                                {t('TextoAyuda')}
                            </p>
                            <div className="redes-news w-100 d-flex justify-content-center">
                                <a
                                    href="https://api.whatsapp.com/send?phone=584123604583"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'whatsapp.svg'
                                        }
                                        alt="Whatsapp"
                                    />
                                </a>
                                <a
                                    href="https://telegram.me/ticketmundo_bot"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'telegram.svg'
                                        }
                                        alt="Telegram"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/ticketmundo_ve"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'instagram.svg'
                                        }
                                        alt="Instagram"
                                    />
                                </a>
                                <a
                                    href="https://m.me/ticketmundo"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'messenger.svg'
                                        }
                                        alt="Messenger"
                                    />
                                </a>
                                <a
                                    href={`mailto:atencionalcliente@ticketmundo.com`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className=""
                                >
                                    <img
                                        style={{
                                            width: '45px',
                                            marginRight: '20px'
                                        }}
                                        src={
                                            process.env
                                                .REACT_APP_URL_IMAGES_COMMONS +
                                            'email.svg'
                                        }
                                        alt="Email"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

const getProperty = (productoOrigin, pCar) => {
    let _propDet = '';
    Object.keys(pCar.variantes).map((v) => {
        let prop = productoOrigin.branch_good_properties.find(
            (n) => n.property_id === parseInt(v.replace('op_', ''))
        );
        let op = productoOrigin.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace('op_', '')))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        _propDet = prop && op ? `${prop.display_name}: ${op.name}` : '';
    });
    return _propDet;
};

export default ConfirmarCompra;

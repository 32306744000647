import axios from 'axios';
const httpInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SHOP_API_URL}companies/`,
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

httpInstance.interceptors.response.use(null, (error) => {
    // const expectedError =
    //     error.response &&
    //     error.response.status >= 400 &&
    //     error.response.status < 500;
    return error.response;
});

export const setHeader = (inst, headers) => {
    inst.defaults.headers = headers;
};

export const getAll = async (list) => {
    let responses = await axios.all(list);
    return responses;
};
export default httpInstance;

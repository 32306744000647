import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { getDetalleComercio } from '../services/branch';
import { setShopInformation } from '../store/shopSlice';
import Categorias from './categorias';
import RealizarPago from './realizarPago';

const Index = () => {
    const shop = useSelector((state) => state.shop);
    const dispatch = useDispatch();
    useEffect(() => {
        if (shop.config.BranchID) {
            getComercioInfo();
        }
        // console.log(shop);
    }, [shop.config]);

    const getComercioInfo = async () => {
        // console.log(branches);
        const infoComercio = await getDetalleComercio(
            shop.config.ShopCurrency,
            shop.config.CompanyID,
            shop.config.BranchID
        );
        // console.log(infoComercio);
        if (infoComercio) {
            dispatch(
                setShopInformation({
                    branches: [],
                    branch: infoComercio
                })
            );
        }
    };

    return (
        <section>
            <Routes>
                <Route path="/" element={<Categorias />} />
                <Route path="/realizarPago" element={<RealizarPago />} />
                <Route path="/categorias/:itemid" element={<Categorias />} />
            </Routes>
        </section>
    );
};

export default Index;

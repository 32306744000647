import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    infoShop: {
        Idioma: 'es'
    },
    config: {},
    branchActive: null,
    branches: [],
    productoModal: null,
    showModal: false,
    loading: false
};

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShopInformation: (state, action) => {
            return {
                ...state,
                infoShop: action.payload.branch,
                branchActive: action.payload.branch.id,
                branches: action.payload.branches
            };
        },
        setShopConfig: (state, action) => {
            return {
                ...state,
                config: action.payload
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const { setShopInformation, setShopConfig } = shopSlice.actions;

export default shopSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    datos: null
};

export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {
        setInfoUser: (state, action) => {
            return {
                datos: action.payload
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const { setInfoUser } = usuarioSlice.actions;

export default usuarioSlice.reducer;
